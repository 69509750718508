<template>
  <div class="wrapper">
    <div class="rule">
      <pre>{{ rule }}</pre>

      <!-- <span>签到规则</span> -->

      <!-- <ul class="rule-ul">
        <li>
          <h2>活动时间</h2>
          <p>10月21日~11月12日</p>
        </li>
        <li>
          <h2>签到规则</h2>
          <p>
            1. 签到1天，领取10元优惠券， 满100可用 <br />
            2. 累计签到7天，赠送木质分装瓶1个<br />
            3. 累计签到10天，赠送999-100元优惠券、线上课程会员1个月（2选1）<br />
            4.
            累计签到15天，赠送单支色料5ml1支、凤凰眼色料一盒、冻干粉一盒（随机发出）
          </p>
        </li>
      </ul> -->
    </div>
  </div>
</template>

<script>
import { getQueryVariable } from "@get/util";
import { get, post } from "@get/http";
export default {
  components: {},

  data() {
    return {
      boo: false,
      start_time: 0,
      end_time: 0,
      rule: "",
    };
  },
  async created() {
    // this.boo = this.show;
    let signin_id = getQueryVariable("signin_id");
    let { rule } = await post("/lw.MemberWelfare/getSigninWelfareConfig", {
      data: {
        id: signin_id,
      },
    });
    this.rule = rule;
  },
  // watch: {
  //   show() {
  //     this.boo = this.show;
  //   },
  //   boo() {
  //     this.$emit("update:show", this.boo);
  //   },
  // },
};
</script>

<style lang="scss" scoped>
@import "~@css/mixin.scss";

.rule {
  background: #fff;
  border-radius: rem(16);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  > span {
    color: #222222;
    font-weight: bold;
    font-size: rem(34);
    text-align: center;
    display: block;
    margin-top: rem(40);
  }
  .rule-ul {
    // width: rem(478);
    padding: rem(30);
    box-sizing: border-box;
    // margin: rem(24) auto 0;
    li {
      color: #666666;
      font-size: rem(28);
      line-height: rem(40);
      h2 {
        font-weight: bold;
        margin-bottom: rem(11);
      }
      p {
        margin-bottom: rem(34);
      }
    }
  }
}
.tips_error {
  position: absolute;
  width: rem(63);
  height: rem(63);
  bottom: rem(-120);
  left: 0;
  right: 0;
  margin: 0 auto;
}
</style>
